import React from "react";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

import { clubUrl, displayPrice } from "../../../lib/helpers";

export default function MobileSubscriptions(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container>
        {props.subscriptions.map((subscription, i) => (
          <Grid
            item
            xs={12}
            container
            style={{ padding: 10 }}
            key={subscription._id}
            className={clsx({ [classes.oddRow]: i % 2 === 1 })}
          >
            <Grid item xs={3} container>
              <ListItem>
                <ListItemText
                  primary={
                    <div className={classes.playerContainer}>
                      <Avatar />
                      {subscription.clubPlayer.firstName}
                    </div>
                  }
                />
              </ListItem>
            </Grid>
            <Grid item xs={9} container alignItems="center">
              <ListItemText
                primary={subscription.team.baseName}
                secondary={
                  subscription.product && (
                    <span>
                      <div>{displayPrice(subscription.totalPrice)} </div>
                      {subscription.debt === 0 && <div>שולם</div>}
                    </span>
                  )
                }
              ></ListItemText>
            </Grid>
            <Grid item xs={12}>
              {subscription.status === "pendingDiscount" && (
                <Alert severity="info">
                  <strong>הוגשה בקשה מיוחדת</strong>
                </Alert>
              )}
              {subscription.debt > 0 && (
                <Alert
                  severity="info"
                  action={
                    (props.clubConfig.paymentMethods === "cc" ||
                      props.clubConfig.paymentMethods === "all") && (
                      <Button
                        variant="contained"
                        color="primary"
                        component={RouterLink}
                        to={clubUrl(
                          props.clubId,
                          `purchase?clubPlayerId=${subscription.clubPlayer._id}&mode=payment&subscriptionId=${subscription._id}`
                        )}
                      >
                        תשלום
                      </Button>
                    )
                  }
                >
                  <strong>
                    ישנה יתרת תשלום על סך {displayPrice(subscription.debt)}
                  </strong>
                </Alert>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Divider />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: 10,
  },
  oddRow: {
    backgroundColor: "rgba(0,0,0,0.03)",
  },
  debtContainer: {
    // padding: 5,
    // marginTop: 10,
    border: "1px solid #CCC",
    // borderRadius: 5,f
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "bold",
  },
  playerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
}));
